<template src="./IdiUniversity.html"></template>

<script>
import AnnouncementOpen from "../../components/AnnouncementOpen/AnnouncementOpen";
import announcementService from "../../services/Announcement";
import newsService from "../../services/News";
import idiService from "../../services/Idi";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import HorizontalCardComingSoon from "../../components/HorizontalCardComingSoon/HorizontalCardComingSoon";
import ArrobaMedellinNoData from "../../components/ArrobaMedellinNoData/ArrobaMedellinNoData";
import AnnouncementCloseHorizontal from "../../components/AnnouncementCloseHorizontal/AnnouncementCloseHorizontal";
import VerticalCardComingSoon from "../../components/VerticalCardComingSoon/VerticalCardComingSoon";
import NewsVerticalCard from "../../components/NewsVerticalCard/NewsVerticalCard";

export default {
  name: "idiUniversity",
  components: {
    ArrobaMedellinArrobitaNoData,
    AnnouncementOpen,
    HorizontalCardComingSoon,
    ArrobaMedellinNoData,
    AnnouncementCloseHorizontal,
    VerticalCardComingSoon,
    NewsVerticalCard,
  },
  data() {
    return {
      openCalls: [],
      allNews: [],
      closedCalls: [],
      role: "",
      institutionId: "",
      countGroups: {},
      countAcademicPlant: {},
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
  },
  mounted() {
    this.getAllInfo();
    this.role = this.$store.state.User.user.role.name;
  },
  methods: {
    async getAllInfo() {
      try {
        const objOpenCalls = {
          state: true,
          institution: this.institutionId,
          publicState: "both",
        };
        const objClosedCalls = {
          state: false,
          institution: this.institutionId,
          publicState: "both",
        };
        const objNews = {
          id: this.institutionId,
          newsType: "institution",
          institutionId: this.institutionId,
        };
        this.myCalls = await announcementService.myAnnouncementByState(1);
        this.openCalls =
          await announcementService.announcementByStateAndInstitution(
            objOpenCalls
          );
        this.closedCalls =
          await announcementService.announcementByStateAndInstitution(
            objClosedCalls
          );
        this.allNews = await newsService.newsByElement(objNews);
        this.countGroups = await idiService.countGroups(this.institutionId);
        this.countAcademicPlant = await idiService.countAcademicPlant(
          this.institutionId
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    calculateCommingSoon() {
      return (quantity, max) => {
        const dif = max - quantity;
        return dif < 0 ? [] : Array(dif);
      };
    },
    roleValidation() {
      return ["University"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./IdiUniversity.scss"></style>